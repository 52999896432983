import axios from '@axios'

const route = 'report/occupation_report'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, filter) {
      return axios.post(route, filter)
    },
    exportPdf(context,filter){
      return axios.post(route + '/export_pdf',filter,{responseType: 'blob'})
    },
  },
}
