import axios from '@axios'

const route = 'alerts'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    sendCheckout(){
      return axios.get(route + '/send_checkout' )
    }

  },
}
