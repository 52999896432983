import axios from "axios";

const baseURL = process.env.VUE_APP_URL_API
const route = baseURL + '/public'

export default {
  namespaced: true,
  
  state: { },
  getters: {},
  mutations: {},
  actions: {

    isRegistered(context, data) {
      return axios.post(`${route}/is_registered`, data);
    },
    get_countries() {
      return axios.get(`${route}/get_countries`);
    },
    get_states() {
      return axios.get(`${route}/get_states`);
    },
    get_cities(context,state_code ) {
      return axios.get(`${route}/get_cities/${state_code}`);
    },
    register_guest(context, data) {
      return axios.post(`${route}/register_guest`, data);
    },
    validate_reserve(context, data) {
      return axios.post(`${route}/validate_reserve`, data);
    }
    
  },
}
