import axios from '@axios'

const route = 'logs'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    reserveLog(context, id) {
      return axios.get(route + '/reserves/'+id )
    }

  },
}
