import axios from '@axios';
import { get } from '@vueuse/core';


const route = 'helper'
export default {
  namespaced: true,
  state: {
    rooms:[],
    roomCategories:[],
    tariffs:[],
    pixs:[],
    cards:[],
    debitCards:[],
    groupProduct:[],
    pdvs:[],
    common_area:[],
    
  },
  getters: {

    getRooms(state){
      return state.rooms
    },
    getRoomCategories(state){
      return state.roomCategories
    },
    getTariffs(state){
      return state.tariffs
    },
    getPixs(state){
      return state.pixs
    },
    getCards(state){
      return state.cards
    },
    getDebitCards(state){
      return state.debitCards
    },
    getGroupProduct(state){
      return state.groupProduct
    },
    getPdvs(state){
      return state.pdvs
    },
    getCommonArea(state){
      return state.common_area
    }

  },

  mutations: {},
  actions: {
    get_countries() {
      return axios.get(`${route}/get_countries`);
    },
    get_states() {
      return axios.get(`${route}/get_states`);
    },
    get_cities(context,state_code ) {
      return axios.get(`${route}/get_cities/${state_code}`);
    },
    loadRooms({state},status){
      let promisse = null
      if(status == null || status == undefined){
        promisse = axios.get(`${route}/get_rooms`)
      }else{
        promisse = axios.get(`${route}/get_rooms/${status}`)
      }

      promisse.then(response=>{
        state.rooms = response.data
      })

      return promisse
    },

    loadRoomCategories({state}){
      const promisse = axios.get(`${route}/get_room_categorys`)
      promisse.then(response=>{
         state.roomCategories = response.data
      })

      return promisse
    },
    loadTariffs({state}){

      const promisse = axios.get(`${route}/get_tariffs`)
      promisse.then(response=>{
         state.tariffs = response.data

      })

      return promisse
    },
    loadPix({state}){
      const promisse = axios.get(`${route}/get_pixs`)
      promisse.then(response=>{
         state.pixs = response.data
      })

      return promisse
    },
    loadCreditCard({state}){
      axios.get(`${route}/get_credit_cards`).then((response)=>{
        state.cards = response.data
      })
    },
    loadDebitCard({state}){
      axios.get(`${route}/get_debit_cards`).then((response)=>{
        state.debitCards = response.data
      })
    },
    loadGroupProduct({state}){
      axios.get(`${route}/get_group_product`).then((response)=>{
        state.groupProduct = response.data
      })
    },
    loadPdvs({state}){
      axios.get(`${route}/get_pdvs`).then((response)=>{
        state.pdvs = response.data
      })
    },
    loadAdmRole(context,credentials){
      return axios.post(`${route}/get_permission`,credentials)
    },
    loadCommonArea({state}){
      axios.get(`${route}/get_common_areas`).then((response)=>{
        state.common_area = response.data
      })
    },
    getRoomsOccupied(){
      return axios.get(`${route}/get_rooms/ocupado`)
    }
    
  },
}
