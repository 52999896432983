import axios from '@axios'

const route = 'events/reserves'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAll(context, { itemsPerPage, page}) {
      return axios.get(`${route}?page=${page}&items_per_page=${itemsPerPage}`)
    },
    store(context, reserve) {
      return axios.post(route, reserve)
    },
    update(context, reserve) {
      return axios.put(`${route}/${reserve.id}`, reserve)
    },
    delete(context, reserve) {
      return axios.delete(`${route}/${reserve.id}`)
    },
    isAvailable(context, data) {
      return axios.post(`${route}/is_available`, data)
    },
    cancelReserve(context, reserve) {
      return axios.post(`${route}/cancel_reserve`, reserve)
    }


  },
}
